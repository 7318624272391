export default [
  {
    path: '/school/list',
    name: 'school-list',
    component: () => import('@/views/school/list/SchoolList.vue'),
    meta: {
      resource: 'school',
      action: 'read',
    },
  },
  {
    path: '/class/list',
    name: 'class-list',
    component: () => import('@/views/class/list/ClassList.vue'),
    meta: {
      resource: 'class',
      action: 'read',
    },
  },
  {
    path: '/student/list',
    name: 'student-list',
    component: () => import('@/views/student/list/StudentList.vue'),
    meta: {
      resource: 'student',
      action: 'read',
    },
  },
  {
    path: '/fee/list',
    name: 'fee-list',
    component: () => import('@/views/fee/list/FeeList.vue'),
    meta: {
      resource: 'fee',
      action: 'read',
    },
  },
  {
    path: '/fee-category/list',
    name: 'fee-category-list',
    component: () => import('@/views/fee-category/list/FeeCategoryList.vue'),
    meta: {
      resource: 'fee-category',
      action: 'read',
    },
  },
];
